import React, { useState } from "react";
import "./index.css";
import logo from './logo.png'
const API_URL = "https://studex.club/api/v1/join/"; // Replace with your actual API endpoint

const App = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    full_name: "",
    specials: "",
    academic_level: "",
    sex: "",
    email: "",
    phone_number: "",
    interest_in_club: "",
    skills_talents: "",
    previous_experience: "",
    event_participation: "",
    social_media_accounts: "",
    additional_comments: "",
    
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const steps = [
    "Personal Details",
    "Academic Details",
    "Club Details",
    "Complete",
  ];

  const specialsOptions = ["INFO_LMD", "INFO_ING", "ST", "SM", "MATH", "OTHER"];
  const academicLevelOptions = ["L1", "L2", "L3", "M1", "M2", "OTHER"];
  const sexOptions = ["M", "W"];
  const interestOptions = [
    "SOCIAL",
    "LEADERSHIP",
    "PROJECTS",
    "LEARNING",
    "OTHER",
  ];
  const skillsOptions = ["PROGRAMMING", "DESIGN", "OTHER"];
  const experienceOptions = ["YES", "NO"];
  const participationOptions = ["YES", "NO"];

  const validateStep = (stepIndex) => {
    const newErrors = {};
    if (stepIndex === 0) {
      if (!formData.full_name) newErrors.full_name = "Full name is required";
      if (formData.full_name.length > 100)
        newErrors.full_name = "Full name must be 100 characters or less";
      if (!formData.email) newErrors.email = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(formData.email))
        newErrors.email = "Email is invalid";
      if (formData.email.length > 254)
        newErrors.email = "Email must be 254 characters or less";
      if (!formData.phone_number)
        newErrors.phone_number = "Phone number is required";
      if (formData.phone_number.length > 15)
        newErrors.phone_number = "Phone number must be 15 characters or less";
      if (!formData.sex) newErrors.sex = "Sex is required";
    } else if (stepIndex === 1) {
      if (!formData.specials) newErrors.specials = "Specialization is required";
      if (!formData.academic_level)
        newErrors.academic_level = "Academic level is required";
    } else if (stepIndex === 2) {
      if (!formData.interest_in_club)
        newErrors.interest_in_club = "Interest in club is required";
      if (!formData.skills_talents)
        newErrors.skills_talents = "Skills and talents are required";
      if (!formData.previous_experience)
        newErrors.previous_experience = "Previous experience is required";
      if (!formData.event_participation)
        newErrors.event_participation = "Event participation is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleNext = () => {
    if (validateStep(step)) {
      setStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep(2)) {
      setIsLoading(true);
      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        if (response.ok) {
          setApiResponse({
            type: "success",
            message: "Student registered successfully!",
          });
          setStep(3);
        } else {
          setApiResponse({ type: "error", message: data.message || "An error occurred" });
          
          // Map the 'errors' from the API to the local form error state
          if (data.errors) {
            const newErrors = {};
            Object.keys(data.errors).forEach((key) => {
              newErrors[key] = data.errors[key][0]; // Display the first error message for each field
            });
            setErrors(newErrors);
          }
        }
      } catch (error) {
        setApiResponse({
          type: "error",
          message: "Network error. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderForm = () => {
    switch (step) {
      case 0:
        return (
          <>
            <input
              type="text"
              name="full_name"
              value={formData.full_name}
              onChange={handleChange}
              placeholder="Full Name"
              className="w-full p-2 border rounded mt-2"
              maxLength={100}
            />
            {errors.full_name && (
              <p className="text-red-500 text-sm">{errors.full_name}</p>
            )}
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full p-2 border rounded mt-2"
              maxLength={254}
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
            <input
              type="tel"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              placeholder="Phone Number"
              className="w-full p-2 border rounded mt-2"
              maxLength={15}
            />
            {errors.phone_number && (
              <p className="text-red-500 text-sm">{errors.phone_number}</p>
            )}
            <select
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-2"
            >
              <option value="">Select Sex</option>
              {sexOptions.map((option) => (
                <option key={option} value={option}>
                  {option === 'M' ? 'Men' : 'Women'}
                </option>
              ))}
            </select>
            {errors.sex && <p className="text-red-500 text-sm">{errors.sex}</p>}
          </>
        );
      case 1:
        return (
          <>
            <select
              name="specials"
              value={formData.specials}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-2"
            >
              <option value="">Select Specialization</option>
              {specialsOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.specials && (
              <p className="text-red-500 text-sm">{errors.specials}</p>
            )}
            <select
              name="academic_level"
              value={formData.academic_level}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-2"
            >
              <option value="">Select Academic Level</option>
              {academicLevelOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.academic_level && (
              <p className="text-red-500 text-sm">{errors.academic_level}</p>
            )}
          </>
        );
      case 2:
        return (
          <>
            <select
              name="interest_in_club"
              value={formData.interest_in_club}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-2"
            >
              <option value="">Select Interest in Club</option>
              {interestOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.interest_in_club && (
              <p className="text-red-500 text-sm">{errors.interest_in_club}</p>
            )}
            <select
              name="skills_talents"
              value={formData.skills_talents}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-2"
            >
              <option value="">Select Skills and Talents</option>
              {skillsOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.skills_talents && (
              <p className="text-red-500 text-sm">{errors.skills_talents}</p>
            )}
            <select
              name="previous_experience"
              value={formData.previous_experience}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-2"
            >
              <option value="">Previous Experience</option>
              {experienceOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.previous_experience && (
              <p className="text-red-500 text-sm">
                {errors.previous_experience}
              </p>
            )}
            <select
              name="event_participation"
              value={formData.event_participation}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-2"
            >
              <option value="">Event Participation</option>
              {participationOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.event_participation && (
              <p className="text-red-500 text-sm">
                {errors.event_participation}
              </p>
            )}
            <input
              type="text"
              name="social_media_accounts"
              value={formData.social_media_accounts}
              onChange={handleChange}
              placeholder="Social Media Accounts (Optional)"
              className="w-full p-2 border rounded mt-2"
            />
            {errors.social_media_accounts && (
              <p className="text-red-500 text-sm">
                {errors.social_media_accounts}
              </p>
            )}
            <textarea
              name="additional_comments"
              value={formData.additional_comments}
              onChange={handleChange}
              placeholder="Additional Comments (Optional)"
              className="w-full p-2 border rounded mt-2"
            />
            {errors.additional_comments && (
              <p className="text-red-500 text-sm">
                {errors.additional_comments}
              </p>
            )}
          </>
        );
      case 3:
        return (
          <div className="text-center">
            {apiResponse ? (
              <div>
                <p
                  className={`text-${
                    apiResponse.type === "success" ? "green" : "red"
                  }-500 text-lg`}
                >
                  {Object.keys(errors).map((key) =>
                    errors[key] ? (
                      <p key={key} className="text-red-500 text-sm">
                        {errors[key]}
                      </p>
                    ) : null
                  )}
                </p>
              </div>
            ) : (
              <p className="text-gray-700 text-lg">Registration Complete</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };
  

  return (
    <div>
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <img className="mb-3 w-16 h-16" src={logo} alt="logo"/>
        <div className="w-full max-w-xl bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4">{steps[step]}</h2>
          <form onSubmit={handleSubmit}>
            {renderForm()}
            <div className="mt-4 flex justify-between">
              {step > 0 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded"
                >
                  Back
                </button>
              )}
              {step < steps.length - 1 ? (
                <button
                  type="button"
                  onClick={handleNext}
                  className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  className={`bg-${isLoading ? "gray" : "green"}-500 hover:bg-${
                    isLoading ? "gray" : "green"
                  }-600 text-white py-2 px-4 rounded`}
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>
          </form>
        </div>
        {/* Updated button-style links */}
        <div className="mt-6 flex flex-wrap justify-center gap-4">
          <a 
            href="https://www.instagram.com/studex.club" 
            className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a 
            href="https://t.me/+tQcxMASXkU00ZTU8" 
            className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <a 
            href="https://studex.club/StudX_Club_APP.apk" 
            className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download App
          </a>
        </div>
      </div>
    </div>
  );

};

export default App;
